export const PAGE = {
    MAPS_DATA: {
        PATH: "/",
        NAME: "MapsAndData"
    },
    REPORT: {
        PATH: "/reports",
        NAME: "Reports"
    },
    ADMINISTRATION: {
        PATH: "/reports",
        NAME: "Reports"
    },
    QUESTION: {
        PATH: "/questions",
        NAME: "Questions"
    },
    DATA: {
        PATH: "/data",
        NAME: "Data"
    },
    ADMIN: {
        PATH: "/admin",
        NAME: "Admin"
    },
    RAISG: {
        PATH: "/raisg",
        NAME: "Raisg"
    }
}