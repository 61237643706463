<template>
  <v-dialog v-model="isShow" persistent max-width="1000px" dialog-border-radius>
    <v-card>
      <div style="display: flex">
        <div>
          <v-img width="600" src="../../assets/images/disclaimer-bg.png"></v-img>
        </div>
        <div style="margin-left: 12px">
          <div style="text-align: center; margin: 12px auto auto;">
            <a href="https://www.raisg.org/" target="_blank"
               rel="noopener noreferrer">
              <img src="../../assets/images/AMA-horizontal.svg" height="70" alt="AMA de RAISG" title="AMA de RAISG"/>
            </a>
          </div>

          <p style="font-weight: 800; font-size: 16px; text-align: justify; margin-right: 24px;" v-html="$t('disclaimer.title')"/>
          <p style="font-size: 14px; text-align: justify; margin-right: 24px;" v-html="$t('disclaimer.description')"/>
          <div style="display: flex;">
            <p style="margin-right: 18px; font-weight: bold" v-html="$t('disclaimer.initiative')"/>
            <a style="margin-right: 10px" href="https://www.raisg.org/" target="_blank" rel="noopener noreferrer">
              <img src="../../assets/images/RAISG_logo_ES_negro.png" height="55" alt="AMA de RAISG"
                   title="AMA de RAISG"/>
            </a>
          </div>
          <div style="margin-top: 10px;">
            <a style="margin-right: 10px" href="https://www.goodenergies.org/" target="_blank"
               rel="noopener noreferrer">
              <img src="../../assets/images/09_good_energies.png" height="45" alt="Good Energies"
                   title="Good Energies"/>
            </a>
            <a style="margin-right: 10px" href="https://www.gouvernement.fr/" target="_blank"
               rel="noopener noreferrer">
              <img src="../../assets/images/10_gouvernement.png" height="45" alt="Gouvernement"
                   title="Conservation"/>
            </a>
            <a style="margin-right: 10px" href="https://www.conservation.org/" target="_blank"
               rel="noopener noreferrer">
              <img src="../../assets/images/11_amazonia.png" height="45" alt="Conservation"
                   title="Conservation"/>
            </a>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green dark-1" text @click="isShow = false" v-html="$t('base.accept')"/>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ADisclaimerAlert",
  data: () => ({
    isShow: false,
  }),
  methods: {
    showLogin() {
      this.isShow = true;
    },
    hideLogin() {
      this.isShow = false;
    },
  },
  created() {
    this.$root.$on('a-disclaimer-show', this.showLogin);
    this.$root.$on('a-disclaimer-hide', this.hideLogin);
  },
  beforeDestroy() {
    this.$root.$off('a-disclaimer-show', this.showLogin);
    this.$root.$off('a-disclaimer-hide', this.hideLogin);
  }
}
</script>

<style scoped>
.title {
  margin: 0px auto;
  text-align: left;
}

.intro {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.875rem;
}

.container_close {
  display: grid;
  grid-template-columns: 1fr 1fr .3fr;
  grid-gap: 10px;
  width: 100%;
  height: 100%;
  max-height: 70px;
}

</style>