<template>
  <v-app-bar class="d-inline d-md-block centre-bar" absolute>
    <v-toolbar-title>
      <a style="margin: auto;" href="https://www.raisg.org/" target="_blank" rel="noopener noreferrer">
        <v-img :src="mainLogo" title="AMA" width="150" class="main-logo" style="margin: 10px"> </v-img>
      </a>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="d-flex align-items-center">
<!--      <v-menu offset-y>-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn text v-bind="attrs" v-on="on">-->
<!--            {{menuMapsTitle}}-->
<!--            <v-icon left> mdi-chevron-down</v-icon>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-list :nav = true>-->
<!--          <v-list-item-group color="green">-->
<!--          <v-list-item v-for="itemMap in menuMapsList" :key="itemMap.title">-->
<!--            <v-list-item-title @click="$router.push({ path: itemMap.path })">-->
<!--            {{ itemMap.titleList }}-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list-item-group>-->
<!--        </v-list>-->
<!--      </v-menu>-->
      <template v-for="item in menuItems">
        <v-btn text :key=item.title v-if="(!item.needRole) || (item.needRole && isAdmin)">
          <router-link :to="item.path">
            <span :class="item.class">{{ $t(item.title)}}</span>
          </router-link>
        </v-btn>
      </template>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on"  >
              <span class="header-lenguage">
                {{ $t(locales[$i18n.locale]) }}
                <v-icon left> mdi-chevron-down </v-icon>
              </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in locales" :key="i" >
            <v-list-item-title @click="setLanguage(i)"  >
              {{ $t(item) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      mainLogo: require("../../assets/images/AMA-horizontal.svg"),

      menuItems: [
        { title: "header.administration", path: "/admin", class:"events", needRole: true },
        { title: "header.raisg", path: "/raisg", class:"", needRole: false },
        { title: "header.maps", path: "/", class:"", needRole: false },
        { title: "header.graphics", path: "/reports", class:"", needRole: false },
        { title: "header.questions", path: "/questions", class:"", needRole: false },
      ],

      locales: {
        es: "header.languages.spanish",
        en: "header.languages.english",
        pt: "header.languages.portuguese",
      },

      isAdmin: false,
    }
  },
  methods: {
    setLanguage(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
      location.reload();
    },
    setLanguageLocationStorage(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
    },
    setShowAdmin() {
      this.isAdmin = true;
    },
    setHideAdmin() {
      this.isAdmin = false;
    }
  },
  created() {
    this.$root.$on('header-show-admin', this.setShowAdmin);
    this.$root.$on('header-hide-admin', this.setHideAdmin);

    let language = localStorage.getItem('language')
    switch(language){
      case 'es':
        this.setLanguageLocationStorage(language)
        break
      case 'en':
        this.setLanguageLocationStorage(language)
        break
      case 'pt':
        this.setLanguageLocationStorage(language)
        break
      default:
        this.setLanguageLocationStorage('es')
        break
    }
  },
  beforeDestroy() {
    this.$root.$off('header-show-admin', this.setShowAdmin);
    this.$root.$off('header-hide-admin', this.setHideAdmin);
  }
}

</script>

<style lang="scss">
.centre-bar{
  display: flex !important;
}
.header-lenguage{
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 17px;
}
.router-link-active {
  .events {color:#BD5037 !important;}
}
.v-btn.v-btn--text, .v-btn.v-btn--text a{
                color:#424242 ;
                text-decoration: none;
                font-size: medium;
                
}
.v-toolbar{
  position: sticky;
        display: flex;
        align-items: center;
        .v-toolbar__content{
            width: 100%;
            justify-content: space-between;
            padding: 4px;
        }    
}
</style>
