import Vue from 'vue'
import VueRouter from 'vue-router'
import {PAGE} from "@/helpers/data-value-common";
import VueAnalytics from "vue-analytics";

Vue.use(VueRouter)

const routes = [
  {
    path: PAGE.MAPS_DATA.PATH,
    name: PAGE.MAPS_DATA.NAME,
    component: () => import('../views/map/Index.vue')
  },
  {
    path: PAGE.REPORT.PATH,
    name: PAGE.REPORT.NAME,
    component: () => import('../views/report/Index.vue')
  },
  {
    path: PAGE.QUESTION.PATH,
    name: PAGE.QUESTION.NAME,
    component: () => import('../views/question/Index.vue')
  },
  {
    path: PAGE.DATA.PATH,
    name: PAGE.DATA.NAME,
    component: () => import('../views/data/Index.vue')
  },
  {
    path: PAGE.ADMIN.PATH,
    name: PAGE.ADMIN.NAME,
    component: () => import('../views/admin/Index.vue')
  },
  {
    path: PAGE.RAISG.PATH,
    name: PAGE.RAISG.NAME,
    beforeEnter() {location.href = 'https://www.raisg.org/es/'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueAnalytics, {
  id: 'G-CB2R82V418',
  router,
});

export default router
