<template>
  <v-footer absolute inset app class="footer">
    <div class="text" v-html=$t(footerLegend)></div>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      footerLegend: "footer.copyright_msg",
    };
  },
};
</script>

<style scoped lang="scss">
$dark-grey: #5B5150;
$grey: #E8E5E0;

.footer{
  width: 100%;
  background-color: $dark-grey !important;
  height: 50px;
  .text{
    color: $grey;
  }
}
</style>
