<template>
  <v-app>
    <a-header/>
    <v-main>
      <router-view class="flex-grow-1" />
    </v-main>
    <a-footer/>
    <a-loader style="z-index: 5000" :showDefault="false"/>
    <a-disclaimer style="z-index: 900" />
  </v-app>
</template>

<script>

import AHeader from "@/components/base/AHeader.vue";
import AFooter from "@/components/base/AFooter.vue";
import ALoader from "@/components/base/ALoader";
import ADisclaimerAlert from "@/components/dialog/ADisclaimerAlert.vue";
import axios from "axios";
import * as Utils from "@/helpers/utils";
import * as LocalStorage from "@/helpers/localstorage";
import {config} from "@/config.json";

export default {
  name: 'App',
  components: {
    'a-header': AHeader,
    'a-footer': AFooter,
    'a-loader': ALoader,
    'a-disclaimer': ADisclaimerAlert,
  },
  data: () => ({
  }),
  methods: {
    showLoader() {
      this.$root.$emit('loader-show');
    },
    hideLoader() {
      this.$root.$emit('loader-hide');
    },
    getUser() {
      let self = this;
      self.error = '';
      self.showLoader();

      let uid = LocalStorage.getUserUID();
      if (uid === null || uid === undefined || uid === "") {
        return;
      }

      axios
          .get(`${config.api.baseURL}/user/${uid}`)
          .then((response) => {
            switch (response.data.descripcion) {
              case "OK": {
                let data = Utils.fromJSON(response.data.jsonCompleto);
                if (data.id_role === 1) {
                  self.$root.$emit('header-show-admin');
                }
                return;
              }
              default: {
                console.log("Login: ", response.data.error);
              }
            }
          })
          .catch((err) => {
            console.log("Login: ", err);
          })
          .finally(() => {
            self.hideLoader()
          });
    }
  },
  mounted() {
    this.$root.$emit('a-disclaimer-show');
  },
  created() {
    this.$root.$on('app-show-loader', this.showLoader);
    this.$root.$on('app-hide-loader', this.hideLoader);

    this.getUser();
  },
  beforeDestroy() {
    this.$root.$off('app-show-loader', this.showLoader);
    this.$root.$off('app-hide-loader', this.hideLoader);
  }

};
</script>

<style lang="scss">
@import "assets/theme/_app.scss";
:root {
  --font-primary: 'Roboto', sans-serif;
  --font-secondary: 'M PLUS 1p', sans-serif;

  --color-background: #F3F6FA;
  --color-main: #3C71AC;
  --color-base: #004593;
  --color-base-sub: #00326A;
  --color-base-blue: #1A95B5;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-yellow: #EDCC09;
  --color-red: #EA5D55;
  --color-lightcoral: #F08080;
  --color-gray: #CFD4DE;
  --color-gray-dark: #9DA9C0;
  --color-gray-light: #EBEFF6;
  --color-gray-oslo: #7F8C8D;
  --color-green: #2DA65B;
  --color-orange: #E67E22;
  --color-sky-blue: #F3F6FA;

  --header-height: 64px;
  --footer-height: 50px;
}
</style>
